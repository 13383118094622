<template>
  <section>
    <validation-observer
      #default="{ invalid, pristine }"
      ref="refFormObserver"
    >
      <b-card
        header-tag="header"
        border-variant="info"
        header-bg-variant="light-info"
        header-class="py-1"
        class="border my-1"
      >
        <template #header>
          <h6 class="m-0">
            {{ $t('customer.tabInfo') }}
          </h6>
        </template>
        <!-- Form Input -->
        <b-card-body class="p-0">
          <!-- Form -->
          <b-form>
            <b-row class="mt-1">
              <!--SECTION information -->
              <b-col cols="12">
                <div class="d-flex mb-50">
                  <feather-icon
                    icon="InfoIcon"
                    size="19"
                  />
                  <h4 class="mb-0 ml-50">
                    {{ $t('customer.accountInfoText') }}
                  </h4>
                </div>
                <b-row>
                  <!-- ANCHOR Last Name -->
                  <b-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Last Name"
                      rules="required|max:30"
                    >
                      <b-form-group
                        :label="`${$t('customer.lastName')} (*)`"
                        label-for="last-name"
                      >
                        <b-form-input
                          id="last-name"
                          v-model="customerDataToAdd.lastName"
                          :state="getValidationState(validationContext) === false ? false : null"
                          maxlength="30"
                          lazy-formatter
                          :formatter="trimInput"
                          :placeholder="$t('customer.placeholderLastName')"
                        />

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- ANCHOR First Name -->
                  <b-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="First Name"
                      rules="required|max:30"
                    >
                      <b-form-group
                        :label="`${$t('customer.firstName')} (*)`"
                        label-for="first-name"
                      >
                        <b-form-input
                          id="first-name"
                          v-model="customerDataToAdd.firstName"
                          :state="getValidationState(validationContext) === false ? false : null"
                          maxlength="30"
                          lazy-formatter
                          :formatter="trimInput"
                          :placeholder="$t('customer.placeholderFirstName')"
                        />

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- ANCHOR Email -->
                  <b-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Email"
                      rules="required|max:50|email|isUnique:customer,email"
                    >
                      <b-form-group
                        :label="`${$t('customer.email')} (*)`"
                        label-for="email"
                      >
                        <b-form-input
                          id="email"
                          v-model="customerDataToAdd.emailAddress"
                          :state="getValidationState(validationContext) === false ? false : null"
                          :formatter="trimInput"
                          :debounce="300"
                          :placeholder="$t('customer.placeholderEmail')"
                        />

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- ANCHOR Phone -->
                  <b-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Phone"
                      rules="required|phoneNumber|isUnique:customer,phonenumber"
                    >
                      <b-form-group
                        :label="`${$t('customer.phoneNumber')} (*)`"
                        label-for="phone"
                      >
                        <div class="d-flex">
                          <b-form-input
                            id="phone"
                            v-model="customerDataToAdd.phoneNumber"
                            v-remove-non-numeric-chars.allNumber
                            :state="getValidationState(validationContext) === false ? false : null"
                            :formatter="trimInput"
                            :debounce="300"
                            :placeholder="$t('customer.placeholderPhoneNumber')"
                          />
                          <!-- maxlength="10" -->
                          <b-button
                            variant="outline-info"
                            class="ml-50 px-1 text-nowrap"
                            :disabled="!isActiveAddMorePhone || !getValidationState(validationContext)"
                            @click="addMorePhoneHandle"
                          >
                            <feather-icon
                              icon="PlusIcon"
                            />
                          </b-button>
                        </div>
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext) === false ? false : null"
                        >
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- ANCHOR Date Of Birth -->
                  <b-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="dob"
                      rules=""
                    >
                      <b-form-group
                        :label="`${$t('customer.dob')}`"
                        label-for="dob"
                      >
                        <b-form-datepicker
                          id="dob"
                          v-model="customerDataToAdd.birthDay"
                          name="dob"
                          class="form-control"
                          :placeholder="$t('customer.placeholderDoB')"
                          :state="getValidationState(validationContext) === false ? false : null"
                          :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                          :max="new Date()"
                          :min="new Date(1920, 0, 1)"
                          show-decade-nav
                          hide-header
                          locale="vi"
                          boundary="window"
                          no-flip
                        />
                      </b-form-group>
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-col>

                  <!-- ANCHOR Gender -->
                  <b-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Gender"
                      rules="required"
                    >
                      <b-form-group
                        :label="`${$t('customer.gender')} (*)`"
                        label-for="gender"
                        :state="getValidationState(validationContext) === false ? false : null"
                      >
                        <v-select
                          v-model="customerDataToAdd.gender"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="genderOptions"
                          :reduce="(val) => val.value"
                          :clearable="false"
                          input-id="gender"
                          :placeholder="$t('customer.placeholderSelectGender')"
                        >
                          <template #option="{value}">
                            <span>
                              {{ $t(value) }}
                            </span>
                          </template>

                          <template #selected-option="{value}">
                            <span>
                              {{ $t(value) }}
                            </span>
                          </template>

                          <template #no-options>
                            {{ $t('customer.noOptions') }}
                          </template>
                        </v-select>
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext) === false ? false : null"
                        >
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>

              <!-- ANCHOR backup Phone -->
              <b-col
                v-for="(phone, index) in customerDataToAdd.backupPhoneNumbers"
                :key="index"
                cols="12"
                sm="6"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="Backup Phone"
                  :rules="`required|phoneNumber|isUnique:customer,phonenumber|duplicatePhone:${customerDataToAdd.phoneNumber},${customerDataToAdd.backupPhoneNumbers.toString()}`"
                >
                  <b-form-group
                    :label="`${$t('customer.backupPhoneNumber')} ${index+1} (*)`"
                    :label-for="`backup-phone-${index}`"
                  >
                    <div class="d-flex">
                      <b-form-input
                        :id="`backup-phone-${index}`"
                        v-model="customerDataToAdd.backupPhoneNumbers[index]"
                        v-remove-non-numeric-chars.allNumber
                        :state="getValidationState(validationContext) === false ? false : null"
                        :formatter="trimInput"
                        :debounce="300"
                        :placeholder="$t('customer.placeholderPhoneNumber')"
                      />
                      <!-- maxlength="10" -->
                      <b-button
                        variant="flat-danger"
                        class="ml-50 px-50 text-nowrap"
                        @click="removePhoneHandle(index)"
                      >
                        <feather-icon icon="XIcon" />
                      </b-button>
                    </div>
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext) === false ? false : null"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR Description -->
              <b-col
                cols="12"
                md="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="Description"
                  rules=""
                >
                  <b-form-group
                    :label="$t('customer.description')"
                    label-for="description"
                  >
                    <b-form-textarea
                      id="description"
                      v-model="customerDataToAdd.description"
                      :placeholder="$t('customer.placeholderDescription')"
                      rows="2"
                      max-rows="4"
                      :state="getValidationState(validationContext) === false ? false : null"
                      class="overflow-auto"
                      lazy-formatter
                      :formatter="trimInput"
                      no-resize
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!-- !SECTION -->

              <!-- SECTION ADDRESS SECTION -->
              <b-col cols="12">
                <div class="d-flex mb-50">
                  <feather-icon
                    icon="MapPinIcon"
                    size="19"
                  />
                  <h4 class="mb-0 ml-50">
                    {{ $t('customer.address') }}
                  </h4>
                </div>
                <b-row>
                  <b-col
                    cols="12"
                    md="8"
                  >
                    <b-row>
                      <!-- ANCHOR addressCountry -->
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <validation-provider
                          #default="validationContext"
                          name="Address Country"
                          rules=""
                        >
                          <b-form-group
                            :label="$t('customer.country')"
                            label-for="addressCountry"
                          >
                            <v-select
                              v-model="customerDataToAdd.addressCountry"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :reduce="country => country.id"
                              :options="countryOptions"
                              label="name"
                              :placeholder="$t('customer.placeholderCountry')"
                              @open="handleFetchCountry"
                            >
                              <template v-slot:no-options="{ search, searching }">
                                <template v-if="searching">
                                  {{ $t('customer.noResultFund') }}<b>{{ search }}</b>
                                </template>
                                <template v-else-if="isLoadingCountry">
                                  <b-spinner
                                    variant="primary"
                                    label="Text Centered"
                                    small
                                    tag="span"
                                  />
                                  <span class="pl-1">
                                    {{ $t('customer.loadingCountryText') }}
                                  </span>
                                </template>
                              </template>
                            </v-select>
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <!-- ANCHOR addressCity -->
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <validation-provider
                          #default="validationContext"
                          name="Address City"
                          rules=""
                        >
                          <b-form-group
                            :label="$t('customer.city')"
                            label-for="addressCity"
                          >
                            <v-select
                              v-model="customerDataToAdd.addressCity"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              label="name"
                              :reduce="country => country.id"
                              :placeholder="$t('customer.placeholderCity')"
                              :options="cityOptions"
                            >
                              <template v-slot:no-options="{ search, searching }">
                                <template v-if="searching">
                                  {{ $t('customer.noResultFund') }}<b>{{ search }}</b>
                                </template>
                                <template v-else-if="isLoadingCity">

                                  <b-spinner
                                    variant="primary"
                                    label="Text Centered"
                                    small
                                    tag="span"
                                  />
                                  <span class="pl-1">
                                    {{ $t('customer.loadingCityText') }}
                                  </span>
                                </template>
                                <template v-else>
                                  <span>{{ $t('customer.needSelect_1') }} <b>{{ $t('customer.country') }}</b> {{ $t('customer.needSelect_2') }}</span>
                                </template>
                              </template>
                              <template v-slot:option="{preName, name}">
                                <div class="d-flex align-items-center">
                                  <span
                                    class="d-block text-nowrap"
                                  >
                                    {{ preName }} {{ name }}
                                  </span>
                                </div>
                              </template>
                            </v-select>
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <!-- ANCHOR address District -->
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <validation-provider
                          #default="validationContext"
                          name="Address District"
                          rules=""
                        >
                          <b-form-group
                            :label="$t('customer.district')"
                            label-for="addressDistrict"
                          >
                            <v-select
                              v-model="customerDataToAdd.addressDistrict"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              label="name"
                              :reduce="country => country.id"
                              :placeholder="$t('customer.placeholderDistrict')"
                              :options="districtOptions"
                            >
                              <template v-slot:no-options="{ search, searching }">
                                <template v-if="searching">
                                  {{ $t('customer.noResultFund') }}<b>{{ search }}</b>
                                </template>
                                <template v-else-if="isLoadingDistrict">

                                  <b-spinner
                                    variant="primary"
                                    label="Text Centered"
                                    small
                                    tag="span"
                                  />
                                  <span class="pl-1">
                                    {{ $t('customer.placeholderDistrict') }}
                                  </span>
                                </template>
                                <template v-else>
                                  <span>{{ $t('customer.needSelect_1') }} <b>{{ $t('customer.city') }}</b> {{ $t('customer.needSelect_2') }}</span>
                                </template>
                              </template>
                              <template #selected-option="{preName, name}">
                                <div class="d-flex align-items-center">
                                  <span
                                    class="d-block text-nowrap"
                                  >
                                    {{ preName }} {{ name }}
                                  </span>
                                </div>
                              </template>
                              <template v-slot:option="{preName, name}">
                                <div class="d-flex align-items-center">
                                  <span
                                    class="d-block text-nowrap"
                                  >
                                    {{ preName }} {{ name }}
                                  </span>
                                </div>
                              </template>
                            </v-select>
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <!-- ANCHOR addressWard -->
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <validation-provider
                          #default="validationContext"
                          name="Address Ward"
                          rules=""
                        >
                          <b-form-group
                            :label="$t('customer.ward')"
                            label-for="addressWard"
                          >
                            <v-select
                              v-model="customerDataToAdd.addressWard"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              label="name"
                              :reduce="country => country.id"
                              :placeholder="$t('customer.placeholderWard')"
                              :options="wardOptions"
                            >
                              <template v-slot:no-options="{ search, searching }">
                                <template v-if="searching">
                                  {{ $t('customer.noResultFund') }}<b>{{ search }}</b>
                                </template>
                                <template v-else-if="isLoadingWard">

                                  <b-spinner
                                    variant="primary"
                                    label="Text Centered"
                                    small
                                    tag="span"
                                  />
                                  <span class="pl-1">
                                    {{ $t('customer.loadingWardText') }}
                                  </span>
                                </template>
                                <template v-else>
                                  <span>{{ $t('customer.needSelect_1') }} <b>{{ $t('customer.district') }}</b> {{ $t('customer.needSelect_2') }}</span>
                                </template>
                              </template>
                              <template #selected-option="{preName, name}">
                                <div class="d-flex align-items-center">
                                  <span
                                    class="d-block text-nowrap"
                                  >
                                    {{ preName }} {{ name }}
                                  </span></div>
                              </template>
                              <template v-slot:option="{preName, name}">
                                <div class="d-flex align-items-center">
                                  <span
                                    class="d-block text-nowrap"
                                  >
                                    {{ preName }} {{ name }}
                                  </span>
                                </div>
                              </template>
                            </v-select>
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </b-col>
                  <!-- ANCHOR addressStreet -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Address Street"
                      rules=""
                    >
                      <b-form-group
                        :label="$t('customer.street')"
                        label-for="address-street"
                      >
                        <b-form-textarea
                          id="address-street"
                          v-model="customerDataToAdd.addressStreet"
                          :placeholder="$t('customer.placeholderStreet')"
                          :rows="['xs','sm'].includes($store.getters['app/currentBreakPoint']) ? 2 : 4"
                          max-rows="4"
                          :state="getValidationState(validationContext) === false ? false : null"
                          class="overflow-auto"
                          lazy-formatter
                          :formatter="trimInput"
                          no-resize
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-form>
        </b-card-body>
      </b-card>

      <!-- SECTION SOCIAL ACCOUNT -->
      <b-card
        header-tag="header"
        border-variant="info"
        header-bg-variant="light-info"
        header-class="py-1"
        body-class="pb-1 pt-50"
        class="border my-1"
      >
        <template #header>
          <h6 class="m-0">
            Mạng xã hội
          </h6>
        </template>

        <b-card-body class="p-0">
          <div v-if="customerDataToAdd.socialAccounts.length > 0">
            <b-row
              v-for="(item, index) in customerDataToAdd.socialAccounts"
              :key="index"
              class="mx-0"
              no-gutters
            >
              <!-- ANCHOR - Tên mạng xã hội -->
              <b-col
                cols="12"
                md="4"
                class="pr-md-1"
              >
                <!-- <validation-provider
                    #default="validationContext"
                    name="Tên mạng xã hội"
                    rules="required"
                  > -->
                <b-form-group
                  :label="`Mạng xã hội #${index + 1}`"
                >
                  <!-- :state="getValidationState(validationContext) === false ? false : null" -->
                  <b-form-input
                    :id="`number-${index}`"
                    v-model="item.type"
                    class="font-weight-bold"
                    placeholder="Nhập vào"
                  />
                </b-form-group>
                <!-- </validation-provider> -->
              </b-col>

              <!-- ANCHOR - Địa chỉ -->
              <b-col
                cols="12"
                md="7"
              >
                <!-- <validation-provider
                    #default="validationContext"
                    name="Địa chỉ"
                    rules="required"
                  > -->
                <b-form-group
                  label="Địa chỉ (Url)"
                >
                  <!-- :state="getValidationState(validationContext) === false ? false : null" -->
                  <b-form-input
                    :id="`url-${index}`"
                    v-model="item.url"
                    class="font-weight-bold"
                    placeholder="Nhập vào"
                  />
                </b-form-group>
                <!-- </validation-provider> -->
              </b-col>

              <!-- ANCHOR - Remove Button -->
              <b-col
                cols="12"
                md="1"
                class="mb-50 d-flex align-items-end justify-content-end"
              >
                <!-- :disabled="(dataToAdd.length === 1) && item.isNew" -->
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="flat-danger"
                  class="p-50"
                  style="margin-bottom: 7px;"
                  @click="handleDeleteItem(index)"
                >
                  <feather-icon
                    icon="XIcon"
                    size="20"
                  />
                </b-button>
              </b-col>

              <b-col
                v-if="customerDataToAdd.socialAccounts.length > 1"
                cols="12"
              >
                <hr class="mt-0">
              </b-col>
            </b-row>
          </div>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="flat-primary"
            class="py-50 px-2 rounded d-flex-center"
            :class="customerDataToAdd.socialAccounts.length === 0 ? 'mt-1' : ''"
            @click="handleAddItem"
          >
            <feather-icon
              icon="PlusIcon"
              size="20"
            />
            <span class="ml-25">Thêm mạng xã hội</span>
          </b-button>
        </b-card-body>
      </b-card>
      <!-- !SECTION -->

      <!-- SECTION Button Actions -->
      <div class="d-flex mt-1 justify-content-center">
        <!-- ANCHOR Back button -->
        <b-button
          variant="secondary"
          class="mr-1"
          @click="backHandle"
        >
          {{ $t('customer.back') }}
        </b-button>

        <!-- ANCHOR Clear button -->
        <b-button
          variant="danger"
          class="mr-1"
          :disabled="pristine"
          @click="clearHandle"
        >{{ $t('customer.clear') }}</b-button>

        <!-- ANCHOR Next button -->
        <b-button
          variant="info"
          :disabled="invalid || !canAccess('customer.createCustomer')"
          @click="nextHandle"
        >
          {{ $t('customer.next') }}
        </b-button>
      </div>
      <!-- !SECTION -->
    </validation-observer>
  </section>

</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BRow, BCol, BCard, BCardBody, BFormTextarea, BFormDatepicker, BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, validate } from 'vee-validate'
import { ref, watch } from '@vue/composition-api'
import vSelect from 'vue-select'

import { getUserData } from '@/api/auth/utils'
import { genderOptions } from '@/constants/selectOptions'

import formValidation from '@core/comp-functions/forms/form-validation'
import { trimInput } from '@core/comp-functions/forms/formatter-input'

import useCustomerHandle from '@customer/useCustomerHandle'

import {
  required, min, email, dateFormat, isUnique,
} from '@validations'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormDatepicker,
    BSpinner,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    customerData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const blankCustomerData = {
      // require
      firstName: '',
      lastName: '',
      emailAddress: '',
      phoneNumber: '',
      creator: getUserData().employeeData.id,
      gender: 'MALE',
      // options
      photo: null,
      description: null,
      birthDay: null,
      addressCountry: null,
      addressCity: null,
      addressDistrict: null,
      addressWard: null,
      addressStreet: null,
      contacts: null,
      backupPhoneNumbers: [],
      socialAccounts: [
        {
          url: null,
          type: null,
        },
      ],
    }

    const customerDataToAdd = ref(JSON.parse(JSON.stringify(blankCustomerData)))
    const resetCustomerData = () => {
      customerDataToAdd.value = JSON.parse(JSON.stringify(blankCustomerData))
    }
    const { refFormObserver, getValidationState, resetForm } = formValidation(resetCustomerData)

    const { addCustomer, fetchCountries, fetchAdministrativeUnits } = useCustomerHandle()
    // Methods
    function saveHandle() {
      emit('update:customer-data', customerDataToAdd.value)
      emit('submit')
    }
    function nextHandle() {
      emit('update:customer-data', customerDataToAdd.value)
      emit('next-step')
    }

    function backHandle() {
      // resetForm()
      this.$router.go(-1)
    }
    function clearHandle() {
      this.$bvModal
        .msgBoxConfirm(this.$t('modal.clearForm'), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) resetForm()
        })
    }

    // Address
    const isLoadingCountry = ref(false)
    const isLoadingCity = ref(false)
    const isLoadingDistrict = ref(false)
    const isLoadingWard = ref(false)

    const countryOptions = ref([])
    const cityOptions = ref([])
    const districtOptions = ref([])
    const wardOptions = ref([])

    const isActiveAddMorePhone = ref(true)

    async function handleFetchCountry() {
      try {
        isLoadingCountry.value = true
        const data = await fetchCountries()
        if (data) {
          countryOptions.value = data.data.items
        }
      } catch (error) {
        console.error(error)
      } finally {
        isLoadingCountry.value = false
      }
    }

    // Watch country
    watch(() => customerDataToAdd.value.addressCountry, newValue => {
      if (!newValue) {
        cityOptions.value = []
        customerDataToAdd.value.addressCity = null
      } else if (newValue) {
        isLoadingCity.value = true
        fetchAdministrativeUnits({ parentId: newValue })
          .then(res => {
            cityOptions.value = res.data.items
          })
          .catch(err => {
            console.error(err)
          })
          .finally(() => {
            isLoadingCity.value = false
          })
        customerDataToAdd.value.addressCity = null
      }
    })

    // Watch City
    watch(() => customerDataToAdd.value.addressCity, newValue => {
      if (!newValue) {
        districtOptions.value = []
        customerDataToAdd.value.addressDistrict = null
      } else if (newValue) {
        isLoadingDistrict.value = true
        fetchAdministrativeUnits({ parentId: newValue })
          .then(res => {
            districtOptions.value = res.data.items
          })
          .catch(err => {
            console.error(err)
          })
          .finally(() => {
            isLoadingDistrict.value = false
          })
        customerDataToAdd.value.addressDistrict = null
      }
    })

    // Watch District
    watch(() => customerDataToAdd.value.addressDistrict, newValue => {
      if (!newValue) {
        wardOptions.value = []
        customerDataToAdd.value.addressWard = null
      } else if (newValue) {
        isLoadingWard.value = true
        fetchAdministrativeUnits({ parentId: newValue })
          .then(res => {
            wardOptions.value = res.data.items
          })
          .catch(err => {
            console.error(err)
          })
          .finally(() => {
            isLoadingWard.value = false
          })
        customerDataToAdd.value.addressWard = null
      }
    })

    // Watch isActive backupPhone
    watch(() => ([...customerDataToAdd.value.backupPhoneNumbers]), () => {
      const backupLength = customerDataToAdd.value.backupPhoneNumbers.length
      if (backupLength === 0) isActiveAddMorePhone.value = true
      else if (backupLength >= 4) isActiveAddMorePhone.value = false
      else {
        isActiveAddMorePhone.value = true
        customerDataToAdd.value.backupPhoneNumbers.forEach(async backupPhone => {
          const { valid } = await validate(
            backupPhone,
            `required|phoneNumber|isUnique:customer,phonenumber|duplicatePhone:${customerDataToAdd.value.phoneNumber},${customerDataToAdd.value.backupPhoneNumbers.toString()}`,
          )
          if (!valid) isActiveAddMorePhone.value = false
        })
      }
    })

    function addMorePhoneHandle() {
      customerDataToAdd.value.backupPhoneNumbers.push('')
    }

    function removePhoneHandle(index) {
      customerDataToAdd.value.backupPhoneNumbers.splice(index, 1)
    }

    function handleDeleteItem(index) {
      customerDataToAdd.value.socialAccounts.splice(index, 1)
    }

    function handleAddItem() {
      customerDataToAdd.value.socialAccounts.push({
        url: null,
        type: null,
      })
    }

    return {
      handleDeleteItem,
      handleAddItem,
      customerDataToAdd,
      addCustomer,
      refFormObserver,
      getValidationState,
      genderOptions,

      // Validations
      required,
      min,
      email,
      dateFormat,
      isUnique,

      // Methods
      backHandle,
      clearHandle,
      nextHandle,
      saveHandle,
      addMorePhoneHandle,
      removePhoneHandle,
      isActiveAddMorePhone,

      // Address
      fetchAdministrativeUnits,
      fetchCountries,
      handleFetchCountry,
      countryOptions,
      cityOptions,
      districtOptions,
      wardOptions,
      isLoadingCountry,
      isLoadingCity,
      isLoadingDistrict,
      isLoadingWard,

      trimInput,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
